<template>
  <div class="commercial-vehicles pb50">
    <div class="banner">
      <ImageWithPlaceholder
        src="/image/commercial-vehicles/adas.jpg"
        alt="Auto Parking Assist (APA)"
      />
      <div class="commercial-box">
        <h1 class="commercial-title">
          Integrated Solutions for Commercial Vehicles
        </h1>
        <ul class="item-list">
          <a href="#isg" class="isg">Integrated Solutions for GSR</a>
          <a href="#software-solutions" class="software-solutions"
            >Software Solutions</a
          >
        </ul>
      </div>
    </div>
    <div id="isg" class="isg-warpper content pt120">
      <h2 class="h2-title isg-title mb30">Integrated Solutions for GSR</h2>
      <!-- <p class="p-text">
        UN ECE and European Union GSR Regulations require that most commercial
        vehicles to have ADAS and DMS safety measures with specific
        implementation deadlines.
      </p>
      <p class="p-text">
        In response to the UN ECE and EU's mandatory regulations, ArcSoft has
        developed integrated solutions that include TD320 for BSIS (Blind Spot
        Information System), MOIS (Moving Off Information System), ISA
        (Intelligent Speed Assistance),and Mono for DDAW (Driver Drowsiness and
        Attention Warning system).
      </p>
      <p class="p-text">
        ArcSoft TD320 has passed related certification tests, including ISA STU
        certification, so that commercial vehicle OEMs, Second Manufacturers,
        and Tier 1 automotive suppliers can use the TD320 products quickly to
        meet the GSR and related regulatory requirements.
      </p> -->
      <div class="face-t-l">
        <p class="p-text face-t-l-text mb24">
          UN ECE and European Union GSR Regulations require that most commercial
          vehicles to have ADAS and DMS safety measures with specific
          implementation deadlines.<br /><br />
          In response, ArcSoft has developed the following integrated solutions
          that meet the regulatory requirements:
        </p>
        <p class="p-text border-l">
          * Product named AITRAK TD320 which supports BSIS (Blind Spot
          Information System), MOIS (Moving Off Information System), ISA
          (Intelligent Speed Assistance) and LDW (Lane Departure Warning).
        </p>
        <p class="p-text border-l mb24">
          * Product named Monolake which supports DDAW (Driver Drowsiness and
          Attention Warning system) and ADDW (Advanced Driver Distraction
          Warning).
        </p>
        <p class="p-text face-t-l-text">
          ArcSoft TD320 and Monolake have passed related certification tests,
          including ISA STU certification, so that commercial vehicles OEMs,
          Second Manufacturers and Tier 1 automotive suppliers can use the
          products to meet the GSR and related regulatory requirements.
        </p>
      </div>
    </div>
    <div class="isg-warpper-td320">
      <div class="isg-warpper-td320-bg"></div>
      <div class="content">
        <div class="supports-box supports-td320">TD320</div>
        <div class="td320-top">
          <p class="p-text td320-tilte">
            In response to the latest EU's mandatory regulation General Safety
            Regulation (GSR), ArcSoft introduces TD320: the integrated hardware
            and software system includes BSIS, MOIS and ISA, which is
            specifically developed to offer bus and truck manufacturers an
            outside cabin solution.
          </p>
          <img
            class="td320-top-bg"
            src="/image/commercial-vehicles/adas4.png"
            alt="TD320-regulation"
          />
        </div>

        <div class="td320-bottom-box mt30">
          <p class="h4-title hardware-title">Hardware design meets</p>
          <p class="p-text hardware-text">
            E_ECE_R10.06 Uniform provisions concerning the approval of vehicles
            with regard to electromagnetic compatibility
          </p>
          <img
            class="td320-img"
            src="/image/commercial-vehicles/td320.png"
            alt="td320"
          />
          <img
            class="td3202-img"
            src="/image/commercial-vehicles/td3202.png"
            alt="td3202"
          />
        </div>
        <div class="td320-bottom-box">
          <p class="h4-title software-title">Software design meets</p>
          <div class="software-div">
            <p class="software-text p-text">
              UN Regulation No. 151 "Uniform provisions concerning the approval
              of motor vehicles with regard to the Blind Spot Information System
              for the Detection of Bicycles"
            </p>
            <p class="software-text p-text">
              UN Regulation No. 159 "Uniform provisions concerning the approval
              of motor vehicles with regard to the Moving Off Information System
              for the Detection of Pedestrians and Cyclists"
            </p>
            <p class="software-text p-text">
              Regulation(EU) 2021/1341 "DDAW" supplementing Regulation (EU)
              2019/2144
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="mono-warpper content">
      <div class="mono-t">
        <div class="mono-text-card">
          <div class="mono-box supports-box">Monolake</div>
          <p class="p-text mono-text">
            ArcSoft provides a complete hardware and software driver monitoring
            system named Monolake which meets Driver Drowsiness and Attention
            Warning (DDAW) and Advanced Driver Distraction Warning (ADDW)
            regulations requirements for compliance with EU's General Safety
            Regulation (GSR I & II).
          </p>
        </div>
        <img
          class="mono-t-img"
          src="/image/commercial-vehicles/s2.png"
          alt="Mono"
        />
      </div>
      <div class="customer">
        <p class="gsr-customer">Customer</p>
        <ul class="trademark-list">
          <li><img src="/image/commercial-vehicles/gsr1.png" alt="" /></li>
          <li><img src="/image/commercial-vehicles/gsr2.png" alt="" /></li>
          <li><img src="/image/commercial-vehicles/gsr3.png" alt="" /></li>
          <li><img src="/image/commercial-vehicles/gsr4.png" alt="" /></li>
          <p class="p-text trademark-text">
            ArcSoft has reached cooperation with more than 10 models of various
            vehicle companies, of which 8 models of bus and truck have entered
            the mass-shipped production stage.
          </p>
        </ul>
      </div>
      <p class="summary-text">Certification Summary - Function</p>
      <div class="summary-list">
        <span
          class="heard-item"
          v-for="item in summaryFunction.heard"
          :key="item"
          >{{ item }}</span
        >
        <span
          class="value-item"
          v-for="(item, index) in summaryFunction.data"
          :key="index"
          >{{ item }}</span
        >
      </div>
      <p class="summary-hardware">Certification Summary - Hardware</p>
      <div class="summary-hardware-list mb40">
        <span class="th hd">Certification</span>
        <span class="hd">R10</span>
        <span class="hd">R118</span>
        <span class="hd">ROHS</span>
        <span class="hd">ELV</span>
        <span class="hd">REACH</span>
        <span class="hd">ISA STU</span>
        <span class="th hd">Monolake</span>
        <span class="yes">√</span>
        <span class="yes">√</span>
        <span class="yes">√</span>
        <span class="yes">√</span>
        <span class="yes">√</span>
        <span>—</span>
        <span class="th hd">TD320</span>
        <span class="yes">√</span>
        <span class="yes">√</span>
        <span class="yes">√</span>
        <span class="yes">√</span>
        <span class="yes">√</span>
        <span class="yes">√</span>
      </div>
      <div class="img-box">
        <img src="/image/commercial-vehicles/paper.png" alt="" />
      </div>
    </div>
    <div id="software-solutions" class="software-solutions-warpper pt80">
      <div class="content">
        <h2 class="h2-title solutions-title">Software Solutions</h2>
        <p class="p-text">
          From individual components to comprehensive systems, ArcSoft offers a
          variety of incabin and smart driving solutions for commercial vehicle
          manufacturers to implement safety features and enable driving
          convenience.
        </p>
      </div>

      <div class="img-text content mt30">
        <div class="img-box">
          <img
            src="/image/commercial-vehicles/s3.png"
            alt="Software Solutions"
          />
        </div>
        <div class="r">
          <h4 class="img-text-title">DMS Package</h4>
          <p class="p-text">
            ArcSoft's Driver Monitoring Systems bring insight into the driver's
            state and behavior. The system primarily focuses on driver
            identification, fatigue monitoring, attention tracking, and the
            detection of dangerous driving behaviors. The system identifies over
            a dozen specific actions, including smoking, using a phone, drinking
            water, yawning, and more.
          </p>
          <p class="p-text">
            The system promptly issues alerts to enhance driver safety and
            awareness. The system helps manufacturers meet the EU GSR I & II
            DDAW and ADDW requirements.
          </p>
        </div>
      </div>
      <div class="img-text-box">
        <div class="img-text content">
          <div class="l">
            <h4 class="img-text-title">Face ID</h4>
            <p class="p-text">
              ArcSoft's Face ID package supports facial recognition for all skin
              colors and meets the requirements of privacy and data security
              regulations.
            </p>
          </div>
          <div class="img-box">
            <img
              src="/image/commercial-vehicles/s4.png"
              alt="Software Solutions"
            />
          </div>
        </div>
      </div>

      <div class="img-text content">
        <div class="img-box">
          <img
            src="/image/commercial-vehicles/s5.png"
            alt="Software Solutions"
          />
        </div>
        <div class="r">
          <h4 class="img-text-title">HOD (Hands Off Detection)</h4>
          <p class="p-text">
            HOD package detects the precise moment when driver is in control of
            a vehicle. This information is crucial for modern driver assistance
            systems. According to UN R79 regulations, Lane Keeping Assist
            Systems (LKAS) are required to issue a warning when hands are
            detected off the wheel.
          </p>
        </div>
      </div>
      <div class="img-text-box">
        <div class="img-text content">
          <div class="l">
            <h4 class="img-text-title">Automatic Passenger Counting System</h4>
            <p class="p-text">
              Automatic Passenger Counting System can monitor passengers as they
              enter and exit public transportation vehicles and improve
              operational planning and help prevent leaving passengers in the
              vehicles for better safety.
            </p>
          </div>
          <div class="img-box">
            <img
              src="/image/commercial-vehicles/s6.png"
              alt="Software Solutions"
            />
          </div>
        </div>
      </div>
      <div class="img-text content mt50">
        <div class="l">
          <h4 class="img-text-title">ADAS Package</h4>
          <p class="p-text">
            Advanced Driver Assistance Systems (ADAS) package provides features
            including Forward Collision Warning (FCW), Lane Departure Warning
            (LDW), Headway Monitoring and Warning (HMW), Pedestrian and Cyclist
            Warning (PCW), to further improve the overall driving experience and
            road safety. ArcSoft ADAS package includes Lane Keeping Assist (LKA)
            functions which continuously monitor the vehicle’s position relative
            to lane boundaries. When an imminent lane departure is detected,
            these systems help keep the vehicle within its lane.
          </p>
        </div>
        <div class="img-box">
          <img
            src="/image/commercial-vehicles/s12.png"
            alt="Software Solutions"
          />
        </div>
      </div>

      <div class="img-text content">
        <div class="stop">
          <img src="/image/commercial-vehicles/50.png" alt="" />
          <img src="/image/commercial-vehicles/stop.png" alt="" />
        </div>
        <div class="l">
          <p class="p-text">
            The ADAS package provides an intelligent speed assistance (ISA)
            functions for the mandatory safety regulations introduced by the
            European Union, reminding the driver when the vehicle exceeds the
            speed limit to avoid speeding accidents.
          </p>
        </div>
      </div>

      <div class="img-text content mt20">
        <div class="img-box">
          <img
            class=""
            src="/image/commercial-vehicles/s8.png"
            alt="Software Solutions"
          />
        </div>

        <div class="r">
          <h4 class="img-text-title">AVM Package</h4>
          <p class="p-text">
            When commercial vehicles such as buses, school buses, trucks and
            industrial vehicles are on the road, there are great safety risks in
            the blind spots around the vehicles. The AVM Package integrates the
            BSD warning system and uses high-definition cameras to stitch a
            360-degree all-round video image around the vehicle and provides
            multiple solutions such as 4/5/6-way and front/rear 3-way, automatic
            and manual calibration. The ArcSoft AVM Package is flexibly adapted
            to various types of commercial vehicles helps the driver to
            intuitively understand the actual situation around the vehicle,
            achieves safe and calm driving, and easily passes complex road
            conditions.
          </p>
        </div>
      </div>
      <div class="content">
        <h4 class="bsis-mois">BSD Package</h4>
        <p class="p-text bsis-mois-text">
          The BSD system combines object detection and recognition technologies
          to detect objects in blind spots in real time, allowing drivers to
          increase their awareness of potential hazards that may exist in their
          blind spots, providing additional safety protections for their
          vehicles.
        </p>
        <p class="p-text bsis-mois-text">
          This BSD Package includes Blind Spot Information System (BSIS) and
          Moving Off Information System (MOIS) system that meets the
          requirements of EU’s General Safety Regulation (GSR) that aimed at
          protecting Vulnerable Road Users.
        </p>
      </div>

      <ul class="bsis-mois-img-list">
        <li>
          <img src="/image/commercial-vehicles/s9.png" alt="" />
          <p>Commercial vehicle right blind</p>
          <p>spot - turning blind spot</p>
        </li>
        <li>
          <img src="/image/commercial-vehicles/s10.png" alt="" />
          <p>Commercial vehicle front blind spot</p>
        </li>
        <li>
          <img src="/image/commercial-vehicles/s11.png" alt="" />
          <p>Commercial vehicle right blind spot</p>
        </li>
      </ul>
      <div class="img-text content">
        <img src="/image/commercial-vehicles/s7.png" alt="Software Solutions" />
        <div class="r">
          <h4 class="img-text-title">DVR Package</h4>
          <p class="p-text">
            The DVR package helps OEMs to meet privacy and data safety
            regulations with data desensitization for pedestrian faces and
            vehicle license plate information to protect privacy and security.
          </p>
          <div class="node-tree-list">
            <div class="node-tree">
              <h4>Supported Camera Type</h4>
              <p>ADAS/DVR</p>
              <p>BSD</p>
              <p>AVM</p>
              <p>APA</p>
              <p>…</p>
            </div>
            <div class="node-tree">
              <h4>Supported Platform Type</h4>
              <p>QC NPU / QC CDSP / MTK VPU</p>
              <p>Ti TiDL / Ambarella</p>
              <p>CVFlow</p>

              <p>…</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageWithPlaceholder from '@/components/ImageWithPlaceholder.vue';
export default {
  name: 'CommercialVehicles',
  components: {
    ImageWithPlaceholder,
  },
  metaInfo: {
    title:
      'Integrated Solutions for Commercial Vehicles: Integrated Solutions for GSR, Software Solutions include DMS, Face ID, HOD, ADAS, and More',
    meta: [
      {
        name: 'description',
        content: `ArcSoft's Integrated Solutions for Commercial Vehicles provide GSR compliance systems and a suite of software solutions, including Driver Monitoring Systems (DMS), Face ID, Hands-Off Detection (HOD), Automatic Passenger Counting, ADAS, Around View Monitoring (AVM), Blind Spot Detection (BSD), and DVR. These advanced technologies significantly enhance vehicle safety, driver monitoring, and operational efficiency for the commercial transportation industry.`,
      },
      {
        name: 'keywords',
        content: `Integrated solutions for commercial vehicles, GSR compliance, Driver Monitoring System, DMS, Face ID, Hands Off Detection, HOD, Automatic Passenger Counting, ADAS, AVM, BSD, Blind Spot Detection, DVR, vehicle safety, commercial vehicle technology, fleet management systems`, // 关键词
      },
    ],
  },
  data() {
    return {
      summaryFunction: {
        heard: ['Regulations', 'Vehicle Type', 'ArcSoft Support'],
        data: [
          'BSIS R151',
          'M2, M3, N2, N3',
          'Certified',
          'MOIS R159',
          'M2, M3, N2, N3',
          'Certified',
          'DDAW 1341',
          'M, N',
          'Certified',
          'ENCAP-DMS',
          'M',
          'Certified',
          'ADDW 1341',
          'M, N',
          'Certified',
          'ISA R1958',
          'M, N',
          'Certified',
          'LDWS R130',
          'M2, M3, N2, N3',
          'Certified',
          'VTA R155',
          'M, N',
          'Certified',
          'R156',
          '-',
          'Certified',
        ],
      },
    };
  },
};
</script>
<style lang="less" scoped>
.commercial-vehicles {
  .content {
    width: 1000px;
    box-sizing: border-box;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    padding-top: 0.1px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .h4-title {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    text-indent: 32px;
    font-weight: normal;
    margin-bottom: 10px;
  }
  .h2-title::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 40px;
    background: #e83021;
    vertical-align: bottom;
    margin-right: 24px;
  }
  .p-text {
    line-height: 18px;
    padding: 0 32px;
    margin: 8px 0;
  }
  .supports-box {
    width: 109px;
    height: 41px;
    background-color: #e83021;
    border-radius: 2px;
    color: #fff;
    font-size: 18px;
    line-height: 41px;
    text-align: center;
  }
  overflow: hidden;
  .banner {
    width: 100%;
    position: relative;
    .commercial-box {
      margin: 0 auto;
      position: absolute;
      bottom: -60px;
      left: calc(50% - 500px);
      z-index: 5;
      .commercial-title {
        font-weight: normal;
        font-size: 54px;
        color: #fff;
        text-align: center;
      }
      .item-list {
        width: 960px;
        margin-top: 246px;
        height: 120px;
        display: inline-flex;
        box-sizing: border-box;
        background: linear-gradient(to bottom, #eaeaeb, #fff);
        padding: initial;
        a {
          font-size: 20px;
          width: 50%;
          list-style: none;
          border: 1px solid #d9d9d9;
          border-left: initial;
          box-sizing: border-box;
          padding: 15px 25px;
          position: relative;
          span {
            display: inline-block;
            width: 150px;
          }
          &:hover::after {
            display: block;
          }
          &:hover {
            color: #e83021;
          }
        }
        a:first-child {
          border-left: 1px solid #d9d9d9;
        }
        .isg {
          background: url(/image/commercial-vehicles/integratedsolution.svg)
              no-repeat 95% 15px e('/') 22px,
            url(/image/commercial-vehicles/go.svg) no-repeat 25px 80px e('/')
              22px;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: none;
            background: url(/image/commercial-vehicles/integratedsolution-action.svg)
                no-repeat 95% 15px e('/') 22px,
              url(/image/commercial-vehicles/go-action.svg) no-repeat 25px 80px
                e('/') 22px;
          }
        }
        .software-solutions {
          background: url(/image/commercial-vehicles/softwawre1.svg) no-repeat
              95% 18px e('/') 22px,
            url(/image/commercial-vehicles/go.svg) no-repeat 25px 80px e('/')
              22px;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: none;
            background: url(/image/commercial-vehicles/softwawre1-action.svg)
                no-repeat 95% 18px e('/') 22px,
              url(/image/commercial-vehicles/go-action.svg) no-repeat 25px 80px
                e('/') 22px;
          }
        }
      }
    }
  }
  .isg-warpper {
    .isg-title {
      font-size: 40px;
      color: #222;
    }
    .face-t-l {
      flex: 1;

      .border-l {
        border-left: 2px solid #d9d9d9;
        padding-left: 20px;

        margin-left: 43px;
        margin-top: initial;
        margin-bottom: initial;
        padding-bottom: 10px;
        box-sizing: border-box;
      }
      p:nth-child(3) {
        padding-bottom: initial;
      }
      .face-t-l-text:first-child {
        margin-top: initial;
      }
    }
  }
  .isg-warpper-td320 {
    margin-top: 70px;
    position: relative;
    color: #fff;
    .supports-td320 {
      position: absolute;
      top: -20px;
    }
    .isg-warpper-td320-bg {
      position: absolute;
      top: 0;
      width: 100%;
      height: 493px;
      background: #222;
    }
    .td320-top {
      display: flex;
      position: relative;
      .td320-tilte {
        width: 421px;
        padding: 55px 0;
      }
      .td320-top-bg {
        width: 480px;
        height: 240px;
        position: absolute;
        right: 0;
        top: -20px;
      }
    }
    .regulation {
      font-size: 18px;
      margin: 20px 0;
    }
    .td320-bottom-box {
      width: 100%;
      box-sizing: border-box;
      border: solid 1px #d9d9d9;
      padding: 9px 16px;
      padding-top: 18px;
      position: relative;
      background: linear-gradient(to bottom, #444, #595959);
      .hardware-title {
        background: url('/image/commercial-vehicles/hardware.svg') 0 center
          no-repeat;
        background-size: 16px 16px;
      }
      .software-title {
        background: url('/image/commercial-vehicles/softwawre.svg') 0 center
          no-repeat;
        background-size: 16px 16px;
      }
      .hardware-text {
        width: 350px;
      }
      .software-div {
        display: flex;
        .software-text {
          width: 33.3%;
          position: relative;
          padding-right: 20px;
        }
        .software-text::before {
          content: '';
          position: absolute;
          left: 0;
          width: 1px;
          height: 80px;
          background-color: #fff;
        }
        .software-text:first-child::before {
          display: none;
        }
      }

      .td320-img {
        width: 166px;
        height: 112px;
        position: absolute;
        left: 500px;
        top: -22px;
      }
      .td3202-img {
        width: 181px;
        height: 112;
        position: absolute;
        right: 60px;
        top: -18px;
      }
    }
  }
  .mono-warpper {
    margin-top: 50px;
    .customer {
      .gsr-customer {
        font-size: 18px;
        margin: 24px 0;
      }
      .trademark-list {
        display: flex;
        flex-wrap: wrap;
        background-color: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(10px);
        justify-content: space-around;
        align-items: center;
        padding: 31px 26px;
        box-sizing: border-box;
        border: solid 1px #d9d9d9;
        aspect-ratio: 960 / 195;

        .trademark-text {
          margin-top: 30px;
          color: #222;
          width: 100%;
        }
      }
    }
    .mono-t {
      display: flex;
      align-items: flex-end;
      .mono-text-card {
        box-sizing: border-box;
        height: 220px;
        border: solid 1px #d9d9d9;
        padding: 45px 24px 0 24px;
        position: relative;
      }
      .supports-box {
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(-50%);
      }
      .mono-text {
        padding-left: initial;
      }
    }
    .mono-t-img {
      width: 480px;
      height: 240px;
    }
    .summary-hardware {
      font-size: 18px;
      margin: 20px 0;
    }
    .summary-hardware-list {
      display: flex;
      flex-wrap: wrap;
      width: 600px;
      font-size: 14px;
      border: 1px solid #d9d9d9;
      color: #222;
      border-bottom: initial;

      .hd {
        background-color: #fafafa;
        text-align: center;
      }
      .th {
        width: 120px;
        text-align: left;
        border-left: initial;
        text-indent: 20px;
      }
      span {
        text-align: center;
        padding: 8px 0;
        width: 80px;
        height: 32px;
        box-sizing: border-box;
        border-bottom: 1px solid #d9d9d9;
        border-left: 1px solid #d9d9d9;
      }
      .yes {
        color: #00b73d;
        font-size: 14px;
        font-stretch: expanded;
      }
    }
    .summary-text {
      font-size: 18px;
      margin-top: 28px;
      margin-bottom: 16px;
    }
    .summary-list {
      display: flex;
      flex-wrap: wrap;
      border: 1px solid #d9d9d9;
      border-bottom: initial;
      span {
        width: 33.3%;
        padding: 8px 20px;
        box-sizing: border-box;
        font-size: 14px;
        color: #222;
        border-bottom: 1px solid #d9d9d9;
        border-right: 1px solid #d9d9d9;
      }
      span:nth-child(3n):not(.heard-item) {
        color: #00b73d;
        border-right: initial;
      }
      .heard-item {
        background-color: #fafafa;
      }
    }
    .img-box,
    .img-box img {
      width: 100%;
    }
  }
  .software-solutions-warpper {
    .img-text-box {
      background-color: #fafafa;
    }
    .solutions-title {
      font-size: 40px;
      color: #222;
      margin-bottom: 20px;
    }

    .img-text {
      display: flex;
      align-items: center;
      .img-box {
        flex: 1;
        img {
          width: 100%;
        }
      }
      .stop {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 130px;
        flex: 1;
      }
      .l {
        width: 50%;
        h4 {
          padding-left: initial;
        }
        p {
          padding-left: initial;
        }
      }
      .r {
        width: 50%;
        > h4 {
          padding-left: 32px;
        }
      }
      .img-text-title {
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: 500;
      }
    }

    .node-tree-list {
      padding-left: 28px;
      .node-tree {
        color: #222;
        position: relative;
        font-size: 14px;

        float: left;
        &::before {
          content: '';
          width: 1.02px;
          display: inline-block;
          height: calc(100% - 28px);
          background-color: #222;
          position: absolute;
          top: 22px;
          left: 8.5px;
          opacity: 0.2;
        }
        h4 {
          font-weight: 500;
          line-height: 24px;
          height: 24px;

          &::before {
            content: '';
            display: inline-block;
            width: 18px;
            height: 18px;
            vertical-align: bottom;
            background: url('/image/commercial-vehicles/dot.svg') no-repeat
              center center;
          }
        }
        p {
          color: #a5a5a5;
          font-size: 14px;
          &::before {
            content: '·';
            width: 18px;
            color: #222;
            height: 18px;
            text-align: center;
            display: inline-block;
            font-size: 18px;
            font-weight: bold;
            line-height: 18px;
            vertical-align: middle;
          }
        }
      }
      .node-tree:first-child {
        margin-right: 20px;
      }
    }

    .bsis-mois {
      font-size: 18px;
      color: #222;
      font-weight: 500;
      margin-top: 40px;
      margin-bottom: 24px;
    }
    .bsis-mois-text {
      padding-left: initial;
    }
    .bsis-mois-img-list {
      display: flex;
      justify-content: center;
      margin-bottom: 50px;
      li {
        img {
          width: 312px;
          height: 148px;
        }
      }
      p {
        font-size: 14px;
        color: #222;
        text-align: center;
      }
    }
  }
}
</style>
